import { Component, OnInit } from '@angular/core';
import { PromocionesService } from '../../services/promociones.service';

@Component({
  selector: 'app-promociones',
  templateUrl: './promociones.component.html',
  styleUrls: ['./promociones.component.css']
})
export class PromocionesComponent implements OnInit {
  promociones: object;

  constructor(private promocionesService: PromocionesService) { }

  ngOnInit() {
    this.promocionesService.getPromociones().subscribe(data =>{
      this.promociones = data;
    });
  }

}
