import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CarouselService {
  private apiURL = 'https://main-sl.com/API/index.php?token=AKD5S4X169JLXIPQ742&data=carousel';

  constructor(private http:HttpClient) {}

  getSlider(){
    return this.http.get(this.apiURL);
  }
}