import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { InicioComponent } from './pages/inicio/inicio.component';
import { NavComponent } from './components/nav/nav.component';
import { SliderComponent } from './components/slider/slider.component';
import { HttpClientModule } from '@angular/common/http';
import { JumbotronComponent } from './components/jumbotron/jumbotron.component';
import { TiposComponent } from './components/tipos/tipos.component';
import { MarcasComponent } from './components/marcas/marcas.component';
import { FooterComponent } from './components/footer/footer.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { FabricantesComponent } from './pages/fabricantes/fabricantes.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { AboutComponent } from './pages/about/about.component';
import { FormularioContactoComponent } from './components/formulario-contacto/formulario-contacto.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MapaComponent } from './components/mapa/mapa.component';
import { FabricanteComponent } from './components/fabricante/fabricante.component';
import { PromocionesComponent } from './pages/promociones/promociones.component';
import { CookieService } from 'ngx-cookie-service';
import { BuscadorComponent } from './components/buscador/buscador.component';
import { CategoriasComponent } from './components/categorias/categorias.component';
import { ListadoProductosComponent } from './pages/listado-productos/listado-productos.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    InicioComponent,
    NavComponent,
    SliderComponent,
    JumbotronComponent,
    TiposComponent,
    MarcasComponent,
    FooterComponent,
    ProductosComponent,
    FabricantesComponent,
    ContactoComponent,
    AboutComponent,
    FormularioContactoComponent,
    MapaComponent,
    FabricanteComponent,
    PromocionesComponent,
    BuscadorComponent,
    CategoriasComponent,
    ListadoProductosComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule
  ],
  providers: [CookieService],
  bootstrap: [AppComponent]
})
export class AppModule { }
