import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'main-frontend';
  cookieValue = 'UNKNOWN';
  
  constructor(
    private cookieService: CookieService,
    private spinner: NgxSpinnerService
    ){ 
      this.spinner.show();

    setTimeout(() => {
      this.spinner.hide();
  }, 2500);
    }

  ngOnInit(): void{
    

    this.cookieValue = this.cookieService.get('MainCookie');
  }

  visible: boolean = true;

  @Output() close: EventEmitter<any> = new EventEmitter();

  onGRDP(){
    this.cookieService.set('MainCookie', 'GDPR');
    this.visible = false;
    if(this.visible){
      this.close.emit(null);
    }
  }

}
