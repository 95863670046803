import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '../../services/email.service';

@Component({
  selector: 'app-formulario-contacto',
  templateUrl: './formulario-contacto.component.html',
  styleUrls: ['./formulario-contacto.component.css']
})
export class FormularioContactoComponent implements OnInit {
  contacto: FormGroup;
  submitted = false;
  alert: boolean;
  titulo = 'Formulario de contacto';
  provincias = ['Alava','Albacete','Alicante','Almería','Asturias','Avila','Badajoz','Barcelona','Burgos','Cáceres',
  'Cádiz','Cantabria','Castellón','Ciudad Real','Córdoba','La Coruña','Cuenca','Gerona','Granada','Guadalajara',
  'Guipúzcoa','Huelva','Huesca','Islas Baleares','Jaén','León','Lérida','Lugo','Madrid','Málaga','Murcia','Navarra',
  'Orense','Palencia','Las Palmas','Pontevedra','La Rioja','Salamanca','Segovia','Sevilla','Soria','Tarragona',
  'Santa Cruz de Tenerife','Teruel','Toledo','Valencia','Valladolid','Vizcaya','Zamora','Zaragoza'];

  constructor(private formBuilder:FormBuilder, private emailService: EmailService) { }

  ngOnInit() {
    this.contacto = this.formBuilder.group({
      nya: ['', Validators.required],
      empresa: ['', Validators.required],
      provincia: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      asunto: ['', Validators.required],
      mensaje: ['', [Validators.required, Validators.minLength(6)]],
      aceptar: [false, [Validators.required]]
  });
  }

  get f() { return this.contacto.controls; }
 
    onSubmit() {
        this.submitted = true;
 
        if (this.contacto.invalid) {
            return;
        }
        
        if(this.contacto.value.aceptar == true){
          this.emailService.sendMail(this.contacto.value).subscribe(()=>{
            this.alert = true;
            setTimeout(() => {
              window.scrollTo(0, 0);
              location.reload();
          }, 2500);
          });
          
        }else{
          this.alert = false;
        }
    }

}
