import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MarcasService {
  private apiURL = 'https://main-sl.com/API/index.php?token=AKD5S4X169JLXIPQ742&data=marcas';

  constructor(private http: HttpClient) { }

  getMarcas(){
    return this.http.get(this.apiURL);
  }

  getMarcasTipo(tipo){
    return this.http.get(`${this.apiURL}&tipo=${tipo}`);
  }
}
