import { Component, OnInit } from '@angular/core';
import { CarouselService } from 'src/app/services/carousel.service';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  carousel: object;

  constructor(private carouselService: CarouselService) { }

  ngOnInit() {
    this.carouselService.getSlider().subscribe( data => {
      this.carousel = data;
    });
  }

}
