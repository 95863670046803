import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private apiUrl = 'https://main-sl.com/API/index.php?token=AKD5S4X169JLXIPQ742&data=email';


  constructor(private http: HttpClient) { }

  sendMail(email){
    const httpsOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post(this.apiUrl, JSON.stringify(email));
  }
}
