import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PromocionesService {
  private apiURL = 'https://main-sl.com/API/index.php?token=AKD5S4X169JLXIPQ742&data=promociones';

  constructor(private http: HttpClient) { }

  getPromociones(){
    return this.http.get(this.apiURL);
  }
}
