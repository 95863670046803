import { Component, OnInit } from '@angular/core';
import { ProductosService } from '../../services/productos.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
  resultados: any;
  hayBusqueda: boolean = false;
  categoria: string;

  constructor(
    private productosService: ProductosService,
    private route: ActivatedRoute
    ) {
      this.categoria = this.route.snapshot.paramMap.get('categoria');
     }

  ngOnInit() {
  }

  buscarProductos(busqueda){
    this.hayBusqueda = true;
    this.resultados = this.productosService.getBusqueda(busqueda);
    if(busqueda == ""){
      this.hayBusqueda = false;
    }
  }

}
