import { Component, OnInit } from '@angular/core';
import { ProductosService } from 'src/app/services/productos.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-listado-productos',
  templateUrl: './listado-productos.component.html',
  styleUrls: ['./listado-productos.component.css']
})
export class ListadoProductosComponent implements OnInit {
  productos: object;
  tipo: any;
  titulo: string;
  buscar:string = '';
  resultados: any;
  public busquedaArr = [];
  hayBusqueda: boolean = false;

  constructor(
    private productosService: ProductosService,
    private route: ActivatedRoute
    ) {}

  ngOnInit() {
    this.tipo = this.route.snapshot.paramMap.get('tipo');
    this.productosService.getProductos(this.tipo).subscribe(data =>{
      this.productos = data;
      this.titulo = this.productos[0].tipo_producto;
    });

  }

  buscarProductos(busqueda){
    this.hayBusqueda = true;
    this.resultados = this.productosService.getBusqueda(busqueda);
    if(busqueda == ""){
      this.hayBusqueda = false;
    }
  }
}
