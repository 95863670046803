import { Component, OnInit, Input } from '@angular/core';
import { CategoriasService } from '../../services/categorias.service';
import { TiposService } from '../../services/tipos.service';

@Component({
  selector: 'app-categorias',
  templateUrl: './categorias.component.html',
  styleUrls: ['./categorias.component.css']
})
export class CategoriasComponent implements OnInit {
  categorias: object;
  tipos: object;
  id: string;

  @Input() categoriaId: string;

  constructor(
    private categoriasService: CategoriasService,
    private tiposService: TiposService
    ) { }

  ngOnInit() {
    this.categoriasService.getCategorias().subscribe( data =>{
      this.categorias = data;
      this.id = this.categorias[0].id;
      if(this.categoriaId !== null){
        this.id = this.categoriaId;
      }
      this.tiposService.getTipo(this.id).subscribe(data =>{
        this.tipos = data;
      });
    });
  }

  seleccionCategoria(categoria){
    this.id = categoria;
    this.tiposService.getTipo(this.id).subscribe(data =>{
      this.tipos = data;
    });
  }


}
