import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.css']
})
export class MapaComponent implements OnInit {
  @ViewChild("madrid", {static:true}) madrid: ElementRef;
  @ViewChild("malaga", {static:true}) malaga: ElementRef;
  madridOK:boolean = true;
  malagaOK:boolean = false;

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  seleccionA(){
    this.renderer.addClass(this.madrid.nativeElement, "active");
    this.renderer.removeClass(this.malaga.nativeElement, "active");
    this.madridOK = true;
    this.malagaOK = false;
  }
  seleccionB(){
    this.renderer.addClass(this.malaga.nativeElement, "active");
    this.renderer.removeClass(this.madrid.nativeElement, "active");
    this.madridOK = false;
    this.malagaOK = true;
  }

}
