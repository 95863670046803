import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductosService {
  private apiURL = 'https://main-sl.com/API/index.php?token=AKD5S4X169JLXIPQ742&data=productos';
  busqueda: any;

  constructor(private http: HttpClient) {

    this.getListado().subscribe(data =>{
      this.busqueda = data;
    });
  }


  getProductos(tipo){
    return this.http.get(`${this.apiURL}&tipo=${tipo}`);
  }

  getListado(){
    return this.http.get(this.apiURL);
  }

  getBusqueda(termino: string){
    let buscarProducto = [];
    termino = termino.toLowerCase();

    for(let buscar of this.busqueda){
      let nombre = buscar.producto.toLowerCase();
      if(nombre.indexOf(termino) >= 0){
        buscarProducto.push(buscar);
      }
    }

    return buscarProducto;
  }
}
