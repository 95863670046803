import { Component, OnInit } from '@angular/core';
import { MarcasService } from '../../services/marcas.service';

@Component({
  selector: 'app-fabricante',
  templateUrl: './fabricante.component.html',
  styleUrls: ['./fabricante.component.css']
})
export class FabricanteComponent implements OnInit {
  hidraulicas: object;
  electronicas: object;

  constructor(private marcasService: MarcasService) { }

  ngOnInit() {
    this.marcasService.getMarcasTipo("1").subscribe( data =>{
      this.hidraulicas = data;
    });
    this.marcasService.getMarcasTipo("2").subscribe( data =>{
      this.electronicas = data;
    });
  }

}
