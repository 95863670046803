import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { ProductosComponent } from './pages/productos/productos.component';
import { ContactoComponent } from './pages/contacto/contacto.component';
import { FabricantesComponent } from './pages/fabricantes/fabricantes.component';
import { AboutComponent } from './pages/about/about.component';
import { PromocionesComponent } from './pages/promociones/promociones.component';
import { ListadoProductosComponent } from './pages/listado-productos/listado-productos.component';


const routes: Routes = [
  { path: 'inicio', component: InicioComponent },
  { path: 'productos', component: ProductosComponent},
  { path: 'categorias/:categoria', component: ProductosComponent},
  { path: 'contacto', component: ContactoComponent},
  { path: 'fabricantes', component: FabricantesComponent},
  { path: 'about', component: AboutComponent},
  { path: 'promociones', component: PromocionesComponent},
  { path: 'listado_productos/:tipo', component: ListadoProductosComponent},
  { path: '**', pathMatch: 'full', redirectTo: 'inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
