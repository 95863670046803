import { Component, OnInit } from '@angular/core';
import { TiposService } from '../../services/tipos.service';

@Component({
  selector: 'app-tipos',
  templateUrl: './tipos.component.html',
  styleUrls: ['./tipos.component.css']
})
export class TiposComponent implements OnInit {
  tipos: any;
  public tiposInicio = [];

  constructor(private tiposService: TiposService) { }

  ngOnInit() {
    this.tiposService.getTipos().subscribe( data =>{
      this.tipos = data;

      for(let tipo of this.tipos){
        if(tipo.posicion == "1"){
          this.tiposInicio[1] = tipo;
        }
        if(tipo.posicion == "2"){
          this.tiposInicio[2] = tipo;
        }
        if(tipo.posicion == "3"){
          this.tiposInicio[3] = tipo;
        }
        if(tipo.posicion == "4"){
          this.tiposInicio[4] = tipo;
        }
        if(tipo.posicion == "5"){
          this.tiposInicio[5] = tipo;
        }
        if(tipo.posicion == "6"){
          this.tiposInicio[6] = tipo;
        }
      }

    });
  }

}
