import { Component, OnInit } from '@angular/core';
import { CategoriasService } from '../../services/categorias.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  categorias: object;

  constructor(
    private categoriasService: CategoriasService,
    private router: Router
    ) { }

  ngOnInit() {
    this.categoriasService.getCategorias().subscribe( data =>{
      this.categorias = data;
    });

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
  });
  }

  redireccion(id){
    this.router.navigate(['/categorias', id]);
  }
}
